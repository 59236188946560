<template>
  <div class="container">
    <div class="result-block" :class="{ correct: isCorrect && !justNext, justNext }">
    <b-row class="align-items-center" :class="{ 'justify-content-end': justNext }">
      <b-col cols="auto" v-if="!justNext">
        <div class="result-icon">
          <RightSvg v-if="isCorrect"/>
          <WrongSvg v-if="!isCorrect"/>
        </div>
      </b-col>
      <b-col v-if="!justNext">
        <div class="result-title">
          {{ isCorrect ? $t('correct') : $t('incorrect') }}
        </div>
      </b-col>
      <b-col cols="auto">
        <b-button class="result-btn" @click="next">{{ $t('next') }}</b-button>
      </b-col>
    </b-row>
    </div>
  </div>
</template>

<script>
import WrongSvg from '@/assets/svg/light_wrong.svg'
import RightSvg from '@/assets/svg/light_right.svg'
import EventBus from '@/services/event-bus'
import { rightAnswerAudio, wrongAnswerAudio } from "../../../helpers/audioEffects"

export default {
  name: "LightCourseBottomResult",
  components: {
    RightSvg,
    WrongSvg
  },
  props: {
    wnr: {
      type: Array,
      default: () => []
    },
    justNext: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isCorrect() {
      return this.wnr.every(item => item === 'r')
    }
  },
  methods: {
    next() {
      this.$emit('next', this.isCorrect)
    }
  },
  mounted() {
    EventBus.$emit('light-course-result', this.isCorrect)
    if(this.isCorrect) {
      rightAnswerAudio()
    } else {
      wrongAnswerAudio()
    }
  }
}
</script>

<style scoped lang="scss">
.result-block {
  background: #FFD2D2;
  color: #EB4942;
  border-radius: 42px;
  padding: 14px 16px;
  &.correct {
    background: #D8FFE0;
    color: #27AE45;
    .result-btn {
      background: #27AE45;
    }
  }
  &.justNext {
    background: $text-themed-light2;
    .result-btn {
      background: $text-themed;
    }
  }
  .result-btn {
    background: #EB4942;
    border: none;
    width: 160px;
    border-radius: 16px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .result-title {
    font-size: 20px;
    font-weight: 700;
  }
  .result-icon {
    background: white;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 52px;
    }
  }
}

@media(max-width: 768px) {
  .result-block {
    .result-btn {
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
    .result-title {
      font-size: 17px;
    }
    .result-icon {
      width: 50px;
      height: 50px;
      svg {
        width: 32px;
      }
    }
  }
}
</style>
