export const rightAnswerAudio = () => {
    playAudioFile('correctgame.wav')
}

export const rightFinishAudio = () => {
    playAudioFile('correct.mp3')
}

export const wrongAnswerAudio = () => {
    playAudioFile('incorrectgame.wav')
}

export const wrongFinishAudio = () => {
    playAudioFile('incorrect.mp3')
}

const playAudioFile = (fileName) => {
    const audio = new Audio(`/sounds/${fileName}`)
    audio.play()
}
