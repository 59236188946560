<template>
  <div class="confirmation-page">
    <div class="container text-center">
      <div class="mini-card mx-auto">
        <div class="mt-5 position-relative arc">
          <div class="position-absolute result-title">{{ lost ? $t('failed') : $t('completed') }}</div>
          <StripSvg/>
          <a class="position-absolute close-btn pointer" @click="close">
            <CloseSvg/>
          </a>
        </div>

        <div class="narrower">
          <div class="d-flex justify-content-center heart-wrapper pt-5">
            <HeartSvg v-for="l in livesInitial"
                      :key="`h-${l}`"
                      class="heart"
                      :class="{ filled: l <= livesLeft }"/>
          </div>
          <div class="mt-4" v-if="!lost">
            <ProgressPie :progress="calculatePercent"
                         :colors="['#48CF81', '#FF6A6A']"
                         size="medium"
                         class="mb-3"/>
            <ScoreBoard v-if="scores > 0"
                        :label="$t('score')"
                        :score="scores"/>
            <ScoreBoard v-if="words > 0"
                        :label="$t('words')"
                        :score="words"
                        :class="{ 'mt-3': words > 0 && scores > 0 }"/>
            <hr/>
          </div>

          <div class="mt-4" v-if="lost">
            <h2>Oops!</h2>
            <div class="mt-5 oops-text mb-5">
              {{ $t('light_failed_text') }}
            </div>
          </div>

          <b-row>

            <b-col>
              <b-button @click="again" variant="text" class="btn-again">
                <div><img src="/images/light/light_again.png" alt=""/></div>
                <div>{{ $t('again') }}</div>
              </b-button>
            </b-col>

            <b-col v-if="!lost">
              <b-button @click="finish" variant="text" class="btn-finish">
                <div><img src="/images/light/light_continue.svg" alt=""/></div>
                <div>{{ $t('continue') }}</div>
              </b-button>
            </b-col>

          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScoreBoard from "./ScoreBoard.vue"
import HeartSvg from '@/assets/svg/heart_empty.svg'
import StripSvg from '@/assets/svg/strip.svg'
import CloseSvg from '@/assets/svg/close4.svg'
import ProgressPie from "../general/ProgressPie.vue"
import { rightFinishAudio, wrongFinishAudio } from "../../../helpers/audioEffects"

export default {
  name: "LightCourseResultPage",
  components: {
    ProgressPie,
    ScoreBoard,
    HeartSvg,
    StripSvg,
    CloseSvg
  },
  props: {
    livesLeft: {
      type: Number,
      default: 5
    },
    livesInitial: {
      type: Number,
      default: 5
    },
    words: {
      type: Number,
      default: 0
    },
    scores: {
      type: Number,
      default: 0
    },
    progress: {
      type: Number,
      default: 0
    },
    slidesSuccessCount: {
      type: Number,
      default: 0
    },
    slidesFailedCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    lost() {
      return this.livesLeft <= 0
    },
     calculatePercent() {
      const total = (this.slidesFailedCount + this.slidesSuccessCount);
      return total > 0 ? (this.slidesSuccessCount / total * 100) : 100
    }
  },
  methods: {
    finish() {
      this.$emit('finish', true)
    },
    again() {
      this.$emit('again')
    },
    close() {
      this.$emit('finish', true)
    }
  },
  mounted() {
    this.$emit('finish', false)
    if(this.lost) {
      wrongFinishAudio()
    } else {
      rightFinishAudio()
    }
  }
}
</script>

<style scoped lang="scss">
.confirmation-page {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #E4F4E2;
}
.mini-card {
  max-width: 400px;
  .arc {
    top: 10px;
  }
}
.narrower {
  max-width: 320px;
  margin: 0 auto;
  background: #fff;
  padding-bottom: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.btn-finish {
  img {
    height: 80px;
  }
  color: #27AE60;
}
.btn-again, .btn-finish {
  font-weight: 700;
}
.btn-again {
  img {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 70px;
  }
  color: #F2C94C;
}
.heart-wrapper {
  gap: 5px;
}
.heart {
  stroke: #FF6A6A;
  fill: #FFF2F2;
  max-width: 42px;
  &.filled {
    fill: #FF6A6A;
  }
}
.close-btn {
  right: 18px;
  top: 23px;
}
.result-title, h2 {
  font-family: 'Nunito';
  color: #009ACB;
}
.result-title, h2 {
  text-align: center;
  left: 0;
  width: 100%;
  top: 10px;
  font-size: 28px;
}
h2 {
  font-size: 32px;
}
.oops-text {
  color: #009ACB;
  font-size: 20px;
  font-weight: 700;
}
</style>
