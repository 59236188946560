<template>
  <div>
    <div class="accom-label">{{ label }}</div>
    <div class="text-center">
      <span class="score-span">{{ score }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScoreBoard",
  props: {
    label: {
      type: String
    },
    score: {
      type: [Number, String]
    }
  }
}
</script>

<style scoped>
.accom-label {
  color: #7E828C;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}
.score-span {
  background: #27AE60;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  padding: 5px;
  width: 167px;
  display: inline-block;
  border-radius: 100px;
}
@media(max-width: 768px) {
  .accom-label {
    font-size: 14px;
  }
  .score-span {
    width: 86%;
    font-size: 16px;
    padding: 3px;
  }
}
</style>
